import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Divider,
  Container,
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FormSection from "../components/FormSection";
import TipSection from "../components/TipSection";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "../contexts/SnackbarContext";
import AreaHeader from "../components/formSection/AreaHeader";

const FormPage = ({ isDataModified, setIsDataModified }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isFormCompleted, setFormCompleted] = useState(undefined);
  const [activeStep, setActiveStep] = useState(-1);
  const [steps, setSteps] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const component = location.state.context;
  const [componentStatusImagePaths, setComponentStatusImagePaths] = useState(
    []
  );
  const [questions, setQuestions] = useState([]);
  const [formValues, setFormValues] = useState({});
  const accountingYearId = localStorage.getItem("accountingYear");
  const [loading, setLoading] = useState(false);
  const [isFieldAutoFilling, setIsFieldAutoFilling] = useState({});
  const { showSnackbar } = useSnackbar();
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  const isLoggedIn = !!localStorage.getItem("token");
  const accountingYearIdNumber = Number(accountingYearId);
  const accountingYear =
    isLoggedIn && userJson.company.accounting_years
      ? new Date(
          userJson.company.accounting_years.find(
            (accountingYear) => accountingYear.id === accountingYearIdNumber
          ).end_date
        ).getFullYear()
      : "";

  useEffect(() => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/me/company/components?accounting_year_id=" +
        localStorage.getItem("accountingYear"),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const newComponentStatusImagePaths = data.map((component) => ({
          id: component.id,
          incomplete_status_image_path: component.incomplete_status_image_path,
          completed_status_image_path: component.completed_status_image_path,
          is_completed: component.company_component_logs[0]?.is_completed,
        }));

        setComponentStatusImagePaths(newComponentStatusImagePaths);
        setLoading(false);
        showSnackbar(
          "Vi nulindberetter selv! Udelad roligt irrelevante felter.",
          "success"
        );
      })
      .catch((error) => console.error("Error:", error));
  }, [showSnackbar]);

  /**
   * Company Area Settings
   */

  const [companyAreaSettings, setCompanyAreaSettings] = useState([]);
  const [activeCompanyAreaSettingIndex, setActiveCompanyAreaSettingIndex] =
    useState(-1);
  const [areaEnabled, setAreaEnabled] = useState(null);

  const setQuestionAnswer = useCallback(
    (questionId, value, isModification = true) => {
      setIsDataModified(isModification);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [`value_${questionId}`]: value,
      }));
    },
    [setIsDataModified]
  );

  const setUnitAnswer = (unitId, value, isModification = true) => {
    setIsDataModified(isModification);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [`unit_${unitId}`]: value,
    }));
  };

  const fetchCompanyName = useCallback(async () => {
    try {
      if (user) {
        const userJson = JSON.parse(user);
        if (userJson?.company?.name) {
          return userJson.company.name;
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/me/company`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data.name;
    } catch (error) {
      console.error("Failed to fetch company VAT number:", error);
    }
  }, [user]);

  const fetchCompanyVatNumber = useCallback(async () => {
    try {
      if (user) {
        const userJson = JSON.parse(user);
        if (userJson?.company?.vat_number) {
          return userJson.company.vat_number;
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/me/company`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data.vat_number;
    } catch (error) {
      console.error("Failed to fetch company VAT number:", error);
    }
  }, [user]);

  const handleAutoFill = useCallback(
    async (question) => {
      try {
        setIsFieldAutoFilling((prevIsFieldAutoFilling) => ({
          ...prevIsFieldAutoFilling,
          [question.id]: true,
        }));

        const [companyVatNumber, companyName] = await Promise.all([
          fetchCompanyVatNumber(),
          fetchCompanyName(),
        ]);

        //Validate vat number, all danish vat numbers are 8 numeric digits
        if (
          !companyVatNumber ||
          companyVatNumber.length !== 8 ||
          isNaN(companyVatNumber)
        ) {
          showSnackbar(
            "Kunne ikke få fat i gyldig CVR nummber. Kontakt venligst support.",
            "error"
          );
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/autofill-endpoint/${question.autofill_endpoint_id}?cvr=${companyVatNumber}&accounting_year_id=${accountingYearId}`,
          {
            headers: {
              "Content-Type": "application/json", // Fixed content type
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (!data?.data?.autofillInput) {
          showSnackbar(
            `Fandt ingen ${question.label} data for ${companyName} fra Erhvervsstyrelsen`,
            "warning"
          );
        } else {
          setQuestionAnswer(question.id, data?.data?.autofillInput);
        }
      } catch (error) {
        showSnackbar(
          "Hov... Der opstod en fejl. Kontakt venligst supporten.",
          "error"
        );
        console.error("Error fetching data:", error);
      } finally {
        setIsFieldAutoFilling((prevIsFieldAutoFilling) => ({
          ...prevIsFieldAutoFilling,
          [question.id]: false,
        }));
      }
    },
    [
      fetchCompanyVatNumber,
      fetchCompanyName,
      showSnackbar,
      setQuestionAnswer,
      accountingYearId,
    ]
  );

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_URL + "/areas?component_id=" + component.id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSteps(data);
        data.length > 0 && setActiveStep(0);
      })
      .catch((error) => {
        console.error("Error:", error);
        return <div>Error: {error}</div>;
      });

    fetch(process.env.REACT_APP_API_URL + "/me/company/area-settings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setCompanyAreaSettings(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [component.id]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/questions?component_id=${component.id}&accounting_year_id=${accountingYearId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        // Initialize formValues based on the fetched data
        const initialFormValues = {};

        data.forEach((question) => {
          const answeredQuestion =
            question.questions && question.questions[0]
              ? question.questions[0]
              : "";
          let answeredValue = "";
          let units = question.units;
          let defaultUnit = question.default_unit;

          // Get answered value from question, if it exists.
          if (answeredQuestion) {
            answeredValue = parseFloat(answeredQuestion.value);

            // Handle question_answer_values if present
            if (
              answeredQuestion.question_answer_values &&
              answeredQuestion.question_answer_values[0]
            ) {
              const questionAnswerValue =
                answeredQuestion.question_answer_values[0];
              answeredValue = parseFloat(questionAnswerValue.unit_value);
              defaultUnit =
                units.find((unit) => unit.id === questionAnswerValue.unit_id) ||
                defaultUnit;
            }
          }

          // Build the initial form values object
          if (answeredQuestion === "" && question.is_auto_fillable) {
            handleAutoFill(question);
          } else {
            initialFormValues[`value_${question.id}`] = answeredValue;
          }
          initialFormValues[`unit_${question.id}`] = defaultUnit.id;
        });

        // Set formValues once with the initial values
        setFormValues(initialFormValues);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [component.id, accountingYearId, handleAutoFill]);

  useEffect(() => {
    setActiveCompanyAreaSettingIndex((prevActiveAreaSetting) => {
      const area = steps[activeStep];
      if (!area || area.id === undefined) return -1;
      return companyAreaSettings.findIndex((e) => e.area_id === area.id);
    });
  }, [steps, activeStep, companyAreaSettings]);

  useEffect(() => {
    const area = steps[activeStep];

    if (!area || area.id === undefined) return setAreaEnabled(null);

    if (!area.optional_display) {
      return setAreaEnabled(true);
    }

    const companyAreaSetting =
      companyAreaSettings[activeCompanyAreaSettingIndex];

    const areaEnableValue =
      (!companyAreaSetting && area.optional_display_default_value) ||
      (companyAreaSetting && companyAreaSetting.enabled);

    setAreaEnabled(areaEnableValue);
  }, [steps, activeStep, companyAreaSettings, activeCompanyAreaSettingIndex]);

  useEffect(() => {
    if (isFormCompleted === undefined) {
      return;
    }

    let isCompleted = isFormCompleted;
    fetch(
      process.env.REACT_APP_API_URL +
        "/questions/answer?accounting_year_id=" +
        accountingYearId +
        "&component_id=" +
        component.id +
        "&is_completed=" +
        isCompleted,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formValues),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsDataModified(false);
        if (isCompleted) {
          showSnackbar("Dine svar er gemt og godkendt", "success");
          navigate("/dashboard");
        } else {
          showSnackbar("Dine svar er gemt til senere", "info");
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    isFormCompleted,
    accountingYearId,
    component.id,
    formValues,
    navigate,
    setIsDataModified,
    showSnackbar,
  ]);

  const handleNext = (isCompleted = true) => {
    setLoading(true);
    // Scroll to top of page
    window.scrollTo(0, 0);

    //Handle completed case with empty fields
    if (isCompleted === true && activeStep === steps.length - 1) {
      //Fill empty fields
      if (Object.values(formValues).some((value) => value === "")) {
        // Get empty fields.
        const emptyFields = Object.entries(formValues)
          .filter(([key, value]) => value === "")
          .map(([key]) => key);

        // Foreach empty field, set the value to 0 on formValues.
        let newFormValues = {};
        emptyFields.forEach((field) => {
          newFormValues[field] = 0;
        });

        setFormValues((prevFormValues) => {
          const updatedFormValues = {
            ...prevFormValues,
            ...newFormValues,
          };

          return updatedFormValues;
        });
      }

      setOpenDialog(true);
    } else if (isCompleted === false && activeStep === steps.length - 1) {
      setOpenDialog(false);
      setFormCompleted(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setLoading(false);
    return;
  };

  const handleBack = () => {
    // Gather form data
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = () => {
    setOpenDialog(false);

    setFormCompleted(true);
  };

  const toggleEnableArea = () => {
    setLoading(true);

    const areaId = steps[activeStep].id;
    const activeCompanyAreaSetting =
      companyAreaSettings[activeCompanyAreaSettingIndex];

    // Determine request method and body
    const isUpdate = !!activeCompanyAreaSetting; // Check if activeCompanySetting exists
    const requestMethod = isUpdate ? "PATCH" : "PUT";
    const requestBody = {
      enabled: activeCompanyAreaSetting?.enabled ? false : true,
      ...(isUpdate ? { id: activeCompanyAreaSetting.id } : { area_id: areaId }), // Add id or area_id based on method
    };

    fetch(`${process.env.REACT_APP_API_URL}/me/company/area-setting`, {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json", // Fixed content type
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the area settings
        setCompanyAreaSettings(
          isUpdate
            ? companyAreaSettings.map((companyAreaSetting) =>
                companyAreaSetting.area_id === areaId
                  ? { ...data.areaSetting }
                  : companyAreaSetting
              )
            : [...companyAreaSettings, data.areaSetting]
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ToggleEnableAreaButton = ({ sx, className, variant }) => {
    return (
      <Button
        variant={variant || "contained"}
        color="primary"
        onClick={toggleEnableArea} // Assuming this function is accessible within scope
        disabled={loading} // Assuming loading is defined
        sx={sx || {}} // Apply default empty object if no sx is passed
        className={className || ""} // Apply default empty string if no className is passed
      >
        {!areaEnabled ? "Tilvælg" : "Fravælg"}
      </Button>
    );
  };

  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom color="primary" sx={{ mb: 4 }}>
        {component.name.split(" - ")[1]}
      </Typography>
      <Box
        sx={{ overflowX: "auto", mb: 4, display: { xs: "none", md: "block" } }}
      >
        <Stepper activeStep={activeStep} sx={{ minWidth: "600px" }}>
          {steps.map((step, index) => (
            <Step key={step.id} completed={activeStep > index}>
              <StepLabel>{step.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ flex: 1, mb: 3 }}>
            {steps[activeStep] &&
              (areaEnabled ? (
                <FormSection
                  questions={questions}
                  activeStep={activeStep}
                  steps={steps}
                  formValues={formValues}
                  setQuestionAnswer={setQuestionAnswer}
                  setUnitAnswer={setUnitAnswer}
                  isDataModified={isDataModified}
                  setIsDataModified={setIsDataModified}
                  handleAutoFill={handleAutoFill}
                  isFieldAutoFilling={isFieldAutoFilling}
                />
              ) : (
                <>
                  <AreaHeader
                    activeStep={activeStep}
                    steps={steps}
                    descriptionTxt={
                      steps[activeStep].optional_display_description ||
                      `Tilvælg rapportering af virksomhedens ${steps[
                        activeStep
                      ].name.toLowerCase()}, eller fortsæt ved at klikke på 'Næste'.`
                    }
                  />
                  <ToggleEnableAreaButton sx={{ mb: 12 }} />
                </>
              ))}

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              {steps[activeStep]?.optional_display && areaEnabled ? (
                <ToggleEnableAreaButton sx={{ mr: 2 }} variant="contained" />
              ) : (
                <Box />
              )}{" "}
              {/* Empty Box to ensure space is reserved if button is not shown */}
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="outlined"
                  disabled={activeStep === 0 || loading}
                  onClick={handleBack}
                  sx={{ mr: 2 }}
                >
                  Tilbage
                </Button>

                {activeStep === steps.length - 1 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleNext(false)}
                    sx={{ mr: 2, color: "white" }}
                    disabled={loading}
                  >
                    Gem til senere
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext(true)}
                  startIcon={
                    activeStep === steps.length - 1 ? <CheckIcon /> : null
                  }
                  disabled={loading}
                >
                  {activeStep === steps.length - 1 ? "Godkend" : "Næste"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            mx: 5,
            borderColor: "#88b3af",
            display: { xs: "none", md: "block" },
          }}
        />
        <Grid item xs={12} md={5}>
          <Box sx={{ flex: 1 }}>
            {areaEnabled ? (
              <TipSection steps={steps} activeStep={activeStep} />
            ) : (
              <TipSection
                steps={steps}
                activeStep={activeStep}
                displayTipHeader={false}
                displayTipText={false}
                displayFiles={false}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          variant="h5"
          color="primary"
          style={{ padding: "3rem" }}
          id="alert-dialog-title"
          textAlign={"center"}
        >
          Tillykke!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign={"center"}>
            {`${component.celebratory_message} for ${accountingYear}.`}
          </DialogContentText>
          <div className="flex justify-center flex-wrap">
            {componentStatusImagePaths
              .sort((a, b) => a.id - b.id)
              .map((componentStatusImagePath) => (
                <Box
                  key={componentStatusImagePath.id}
                  component="img"
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${
                    componentStatusImagePath.is_completed ||
                    component.id === componentStatusImagePath.id
                      ? componentStatusImagePath.completed_status_image_path
                      : componentStatusImagePath.incomplete_status_image_path
                  }`}
                  alt={`Component ${componentStatusImagePath.id} completion status`}
                  className="w-1/4 object-contain m-2" // Tailwind CSS classes for responsive images with margin
                />
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleCloseDialog}
            disabled={loading}
            color="primary"
          >
            Tilbage
          </Button> */}
          <Button
            onClick={handleConfirmDialog}
            color="primary"
            disabled={loading}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FormPage;
